/* sidebar blurred avatar background */
//
/* if backdrop-filter is supported, */
/* set the user avatar (if any) as a background so */
/* it can be blurred by the tag panel and room list */

.mx_RoomSublist_showNButton {
    background-color: transparent !important;
}

a:hover,
a:link,
a:visited {
    text-decoration: none;
}
